import resolveImage from "./resolveImage";

const resolveContentBuilder = blocks => {
  const resolvedblocks = [];
  blocks.forEach(block => {
    const { type, uid } = block;
    switch (type) {
      case "lead":
        resolvedblocks.push({
          type,
          uid,
          copy: block.copy,
        });
        break;
      case "copy":
        resolvedblocks.push({
          type,
          uid,
          copy: block.copy,
        });
        break;
      case "video":
        resolvedblocks.push({
          type,
          uid,
          videoUrl: block.videoUrl,
          caption: block.caption,
        });
        break;
      case "blockQuote":
        resolvedblocks.push({
          type,
          uid,
          copy: block.testimonial?.[0].quote,
          attribution: block.testimonial?.[0].name,
          position: block.testimonial?.[0].position,
          image: resolveImage(block.testimonial?.[0].image),
          link: block.cta,
        });
        break;
      case "images":
        resolvedblocks.push({
          type,
          uid,
          images: block.images,
          caption: block.caption,
        });
        break;
      case "cta":
        resolvedblocks.push({
          type,
          uid,
          heading: block.heading,
          description: block.descriptor,
          formId: block.hubspotFormId,
        });
        break;
      case "button":
        resolvedblocks.push({
          type,
          uid,
          url: block.linkLink?.url,
          text: block.linkLink?.text,
          style: block.style,
        });
        break;
      case "embedCode":
        resolvedblocks.push({
          type,
          uid,
          html: block.html,
          javascript: block.javascript,
        });
        break;
      case "blockQuoteCustom":
        resolvedblocks.push({
          type,
          uid,
          copy: block.quote,
          attribution: block.attribution,
        });
        break;
      case "stats":
        resolvedblocks.push({
          type,
          uid,
          stats: block.stats,
        });
        break;
      case "questionAnswer":
        resolvedblocks.push({
          type,
          uid,
          question: block.question,
          answer: block.answer,
        });
        break;
      default:
        // do nothing
        break;
    }
  });

  return resolvedblocks;
};

export default resolveContentBuilder;
