import React from "react";
import loadable from "@loadable/component";

const PageContainer = loadable(() => import("@layout/DefaultPageContainer"));

const ArticleHero = loadable(() => import("@organisms/ArticleHero"));
const ContentBuilder = loadable(() => import("@organisms/ContentBuilder"));
const EndofPageCta = loadable(() => import("@organisms/EndofPageCta"));
const ShareContainer = loadable(() => import("@organisms/ShareContainer"));

const HomePage = ({ data, ...rest }) => {
  const { hero, blocks, share } = data;
  return (
    <PageContainer {...rest} flex={false}>
      <ArticleHero {...hero} />
      <ContentBuilder blocks={blocks} />
      <ShareContainer share={share} center color="pink" />
      <EndofPageCta />
    </PageContainer>
  );
};

HomePage.defaultProps = {};

export default HomePage;
