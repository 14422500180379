import React from "react";
import { graphql } from "gatsby";
import resolveImage from "@dataResolvers/resolveImage";
import resolveArticle from "@dataResolvers/resolveArticle";
import resolveContentBuilder from "@dataResolvers/resolveContentBuilder";
import PageTemplate from "@pageTemplates/ArticlesSinglesPage";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query ArticleSingleQuery($uri: [String]) {
    craft {
      entry(uri: $uri) {
        ... on Craft_articles_default_Entry {
          title
          postDate
          uid
          url
          slug
          authors {
            ... on Craft_people_default_Entry {
              name: title
              uid
              slug
              headshot: image0 {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
          }
          articleCategory {
            uid
            title
            slug
            url
          }
          topics {
            uid
            title
            slug
          }
          # Content Builder
          contentBuilder {
            ...ContentBuilderFragment
          }
          # Meta
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry }) => {
  const {
    // Content Builder
    contentBuilder,
    // Meta
    url,
    title,
    metaTitle,
    metaDescription,
    metaImage,
    slug,
  } = entry;

  return {
    hero: resolveArticle(entry),
    blocks: resolveContentBuilder(contentBuilder),
    share: {
      url,
    },
    meta: {
      url,
      title,
      metaPageTitle: metaTitle,
      metaDescription,
      metaFeaturedImage: resolveImage(metaImage),
      slug,
    },
  };
};

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  return <HeadTemplate {...rest} data={resolvedData} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="ArticlesDefaultQuery"
    {...props}
  />
);

export default Page;
