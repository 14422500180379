import resolveImage from "./resolveImage";

const resolveArticle = entry => {
  const { metaImage, image0, articleCategory, authors, postDate, topics } =
    entry;

  return {
    ...entry,
    type: articleCategory?.[0]?.slug || "blog",
    categories: articleCategory?.map(c => ({
      uid: c.uid,
      title: c.title,
      slug: c.slug,
      url: c.url,
    })),
    topics: topics?.map(c => ({
      uid: c.uid,
      title: c.title,
      slug: c.slug,
    })),
    image: resolveImage(metaImage || image0),
    date: new Date(Date.parse(postDate)).toLocaleDateString("en-US", {
      // weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: "America/New_York",
    }),
    authors: authors?.length
      ? authors.map(a => a.name).join(", ")
      : "CDI Staff",
  };
};

export default resolveArticle;
